<template>
  <div id="app" style="background-image: url(/bk.png)"></div>
</template>

<script>
export default {
  name: 'App',
  components: {
  }
}
</script>
<style>

*{
  padding:0;
  margin:0;
}

html, body{
  width: 100vw;
  height: 100vh;
  overflow:hidden;
}

#app{
  width:100vw;
  height:100vh;
  background-position:center;
  background-size: cover;
  -moz-background-size:cover;
  -webkit-background-size: cover;
}


</style>